import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import '../../global.css'
import favicon from '../../favicon.png'
import Nav from '../../navigation.js'
import Footer from '../../footer.js'

import carsBg from '../../carsBg.jpg'

var splendoBlue = '#2F76CA'

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  .sectionHeader {
    font-size: 1.5em;
    padding: 30px 0;
  }
`

const Hero = styled.header`
  text-align: left;
  position: relative;
  height: 50%;
  background-image: url(${carsBg});
  background-size: cover;
  background-position: center;
  @media (max-width: 800px) {
    text-align: center;
  }
  .wrapper {
    position: absolute;
    top: 50%;
    width: 100%;
    max-width: 600px;
    padding-left: 10%;
    transform: translateY(-50%);
    @media (max-width: 800px) {
      padding: 0;
    }
    h1 {
      font-size: 3em;
      @media (max-width: 800px) {
        font-size: 2em;
      }
    }
    span {
      font-family: nunitoReg, sans-serif;
    }
  }
`
const Details = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 100px 0;
  @media (max-width: 800px) {
    text-align: center;
  }
  h1 {
    padding-bottom: 30px;
  }

  p {
    font-family: nunitoReg, sans-serif;
    padding: 0 0 40px;
    width: 90%;
    max-width: 800px;
    @media (max-width: 800px) {
      font-size: 1em;
      text-align: center;
      width: 100%;
    }
  }

  a {
    display: inline-block;
    padding: 20px 40px;
    color: white;
    border-radius: 12px;
    background: ${splendoBlue};
    text-transform: uppercase;
  }
`
const Wrapper = styled.div`
  position: relative;
  height: 100%;
`

export default () => (
  <Container>
    <Wrapper>
      <Helmet>
        <title>Splendo</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Splendo" />
        <link rel="icon" type="image/png" href={favicon} sizes="50x50" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      </Helmet>

      <Nav />

      <Hero>
        <div className="wrapper">
          <h1>Tacx / Garmin: World's most sophisticated home trainers</h1>
        </div>
      </Hero>

      <Details>
        <h1 className="sectionHeader">About Tacx</h1>
        <p>
          Tacx, a subsidiary of Garmin, is the world leader in the development of in-home cycling trainers. Their devices are used across the world by both professional and regular cyclists to train indoors for the next outdoor race.
        </p>
        <p>
          Splendo and Tacx collaborated in building the mobile training app used to control the trainers as well as the Cloud platform on which all training data is collected, providing cyclists detailed reports of all their workouts and progress over time. After the acquisition of Tacx by Garmin, high quality code and documentation ensured a seamless handover to their in-house team for further development.
        </p>
        <p>
          Cyclists can train while the app simulates real road conditions by either importing a GPS based track or by streaming a high quality video. During this simulation cycling will become more or less difficult depending on whether the road is going up or down. Video streaming will adjust playback speed depending on the speed of the cyclist, ensuring a real time experience. In addition Splendo developed a multiplayer solution, showing other cyclists on their virtual location during training.
        </p>

        <a href="https://tacx.com/" target="_blank" rel="noopener noreferrer">
          View Website
        </a>
      </Details>

      <Footer />
    </Wrapper>
  </Container>
)
